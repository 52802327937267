import * as bootstrap from "bootstrap";
import ready, { HTML } from "./utils";
import initListGridView from './listGrigView';
import initStickyThead from './stickyThead';
import initSticky from "./sticky";

ready(() => {
  HTML.classList.add('is-loaded');
  initListGridView();
  initStickyThead();
  InitSelectBox();
  InitCustomBootstrapComponents();
  InitPasswordToggle();
  InitPasswordToggleForm();
  InitValidation();
  InitInputChange();
  initScrollUp();
  initAddClasses();
  initDots();
  initSticky();
});

["turbo:load", "turbo:frame-load"].forEach(function (evt) {
  document.addEventListener(evt, () => {
    initListGridView();
    initStickyThead();
    InitSelectBox();
    InitCustomBootstrapComponents();
    InitValidation();
    InitInputChange();
    initScrollUp();
    initAddClasses();
    initDots();
    initSticky();
  });
});

jQuery(window).on("load", () => {
  [...jQuery(".general-tab-content")].forEach((tabEl) => {
    InitProgressBarAnimation(tabEl);
  });
});

function initDots() {
  const elements = document.querySelectorAll(
    ".ProductTable .competitors-list .name .text-truncate, .ProductTable .competitors-list .name"
  );

  if (!elements) return;

  elements.forEach((el) => {
    console.log(el, el.offsetWidth);
    if (el.offsetWidth >= 370) {
      el.classList.add("dots-active");
    }
  });
}

function InitCustomBootstrapComponents() {
  //init tooltip
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

  //init popover
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
  const CustomPopoverTriggerList = document.querySelectorAll('.table-popover');
  const CustompopoverList = [...CustomPopoverTriggerList].map(popoverTriggerEl => {
    let popupContentID = jQuery(popoverTriggerEl).attr("data-popup-id");

    return new bootstrap.Popover(
      popoverTriggerEl, {
        html: true,
        content: jQuery(`#${popupContentID}`)
      });

  });

  //init animation for bars
  [...jQuery('.holder-animation')].forEach(myCollapsibleEl => {
    myCollapsibleEl.addEventListener('shown.bs.collapse', event => {
      InitProgressBarAnimation(myCollapsibleEl);
    });
    myCollapsibleEl.addEventListener('hidden.bs.collapse', event => {
      ResetProgressBarAnimation(myCollapsibleEl);
    })
  });
}

function InitSelectBox() {
  jQuery('.custom-select-box').select2({
    minimumResultsForSearch: Infinity,
    allowClear: true
  });
};

function InitPasswordToggle() {
  // Selecciona todos los elementos con la clase 'togglePassword'
  document.querySelectorAll(".togglePassword").forEach(function (toggle) {
    toggle.addEventListener("click", function () {
      var passwordField = this.previousElementSibling;
      var passwordFieldType = passwordField.getAttribute("type");
      if (passwordFieldType === "password") {
        passwordField.setAttribute("type", "text");
      } else {
        passwordField.setAttribute("type", "password");
      }
      this.classList.toggle("show");
    });
  });
}

function InitPasswordToggleForm() {
  // Selecciona todos los elementos con la clase 'togglePassword'
  document.querySelectorAll("#togglePassword").forEach(function (toggle) {
    toggle.addEventListener("click", function () {
      var passwordInput = this.closest(".input-group").querySelector("input");
      var currentType = passwordInput.getAttribute("type");
      passwordInput.setAttribute(
        "type",
        currentType === "password" ? "text" : "password"
      );
      if (currentType === "password") {
        this.className = "far fa-eye-slash"; // Cambiar a la clase 'hide'
      } else {
        this.className = "far fa-eye"; // Cambiar a la clase 'show'
      }
    });
  });
}

function InitValidation() {
	(() => {
		'use strict'

		// Fetch all the forms we want to apply custom Bootstrap validation styles to
		const forms = document.querySelectorAll('.needs-validation')

		// Loop over them and prevent submission
		Array.from(forms).forEach(form => {
			form.addEventListener('submit', event => {
				if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()
				}
				form.classList.add('was-validated')

        var inputs = [...form.querySelectorAll('input')];

        inputs.forEach(input => {
          if ($(input).is(":invalid")) {
            jQuery('.invalid-feedback').show();
          }
        });

        jQuery('.error-close').click(function () {
          jQuery('.invalid-feedback').hide();
        });

			}, false)


		});
	})()
}

function InitInputChange() {
  const field = document.querySelector('.create-form input');

  if (!field) return;

  function inputCheck(e) {
    if (e.target.value) {
      field.classList.add('filled');
    } else {
      field.classList.remove('filled');
    }
  }

  field.addEventListener('change', inputCheck);
}

function InitProgressBarAnimation(parent) {
  [...jQuery(parent).find('.anim-bar')].forEach(item => {
    const bar = jQuery(item);

    const width = bar.attr("aria-valuenow");

    bar.animate({
      width: width + '%',
    }, 1000);
  });
}

function ResetProgressBarAnimation(parent) {
  [...jQuery(parent).find('.anim-bar')].forEach(item => {
    const bar = jQuery(item);

    bar.width('0');
  });
}

function initScrollUp() {
  const screenHeight = jQuery(window).innerHeight();
  const element = jQuery('.back-top');
  var lastScrollTop = 0;

  function toggleClass() {
    const documentHeight = jQuery(document).innerHeight();

    if (documentHeight < screenHeight * 2) return;

    const st = jQuery(window).scrollTop();
    element.toggleClass('visible', st > lastScrollTop);
  }

  element.on('click', function(e) {
    e.preventDefault();
    jQuery('html, body').animate({scrollTop:0}, 700);
  });

  function debounce(func, timeout = 100){
    let timer;

    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.call(this); }, timeout);
    };
  }

  const debouncedToggleClass = debounce(toggleClass);

  jQuery(window).on('scroll', debouncedToggleClass);
}

// add class on click
function initAddClasses() {
	jQuery('.btn-favorite').clickClass({
		classAdd: 'checked'
	});
}


/*
 * Add class plugin
 */
jQuery.fn.clickClass = function(opt) {
	var options = jQuery.extend({
		classAdd: 'add-class',
		addToParent: false,
		event: 'click'
	}, opt);

	return this.each(function() {
		var classItem = jQuery(this);
		if(options.addToParent) {
			if(typeof options.addToParent === 'boolean') {
				classItem = classItem.parent();
			} else {
				classItem = classItem.parents('.' + options.addToParent);
			}
		}
		jQuery(this).bind(options.event, function(e) {
			classItem.toggleClass(options.classAdd);
			e.preventDefault();
		});
	});
};