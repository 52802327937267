import StickySidebar from 'sticky-sidebar';

// #10-01
export default function initSticky() {
  const sidebar = document.getElementById('sidebar');
  const innerClassName = '.sidebar__inner';

  if (!sidebar) return;

  let stickySidebar;
  const content = document.getElementById('content');
  const collapseItems = document.querySelectorAll('.collapse');
  const tabs = document.querySelectorAll('.nav-link');

  initSticky();

  collapseItems.forEach((collapse) => {
    collapse.addEventListener('shown.bs.collapse', handleUpdateSticky);

    collapse.addEventListener('hidden.bs.collapse', handleUpdateSticky);
  });

  tabs.forEach((tab) => {
    tab.addEventListener('shown.bs.tab', handleUpdateSticky);

    tab.addEventListener('hidden.bs.tab', handleUpdateSticky);
  });

  function handleUpdateSticky() {
    const contentHeight = content.getBoundingClientRect().height;
    const stickyHeight = sidebar.getBoundingClientRect().height;

    if (contentHeight < stickyHeight && stickySidebar) {
      stickySidebar.destroy();
      stickySidebar = null;
    } else if (contentHeight >= stickyHeight) {
      if (stickySidebar) {
        stickySidebar.updateSticky();
      } else {
        initSticky();
      }
    }
  }

  function initSticky() {
    stickySidebar = new StickySidebar(sidebar, {
      containerSelector: '#main-content',
      innerWrapperSelector: innerClassName,
      topSpacing: 20,
      bottomSpacing: 20,
    });
  }
}
