// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
global.$ = jQuery;

import "core-js/stable";
import "regenerator-runtime/runtime";

import "../src/products";
import "../src/prices";
import "../src/downloads";
import "../src/dp";

import Rails from "@rails/ujs"
Rails.start();
import * as Turbo from "@hotwired/turbo";
window.Turbo = Turbo;
import "channels"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import * as bootstrap from "bootstrap";
import "../src/app";
import "select2";
import "ion-rangeslider";
import "trix";
import "@rails/actiontext";
import Lightbox from "bs5-lightbox";

import langpl from "select2/src/js/select2/i18n/es";

import Highcharts from "highcharts/highstock";
require("highcharts/modules/xrange")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);
require("highcharts/modules/stock")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/series-label")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
window.Highcharts = Highcharts;

import * as echarts from "echarts";
window.echarts = echarts;

import "../stylesheets/application";

import LocalTime from "../src/local_time.js";
window.LocalTime = LocalTime;
import { UpdateModalContent } from "../src/clean_modals";
export { UpdateModalContent };
import "../src/change_csv_url";
import "../src/analytics.js.erb";

import * as Clipboard from "clipboard";
import { ev } from "../src/utils/index.js";
new Clipboard(".clipboard-btn");

document.addEventListener("DOMContentLoaded", function () {
  // Selecciona todos los elementos con la clase 'clipboard-btn'
  const clipboardButtons = document.querySelectorAll(".clipboard-btn");

  clipboardButtons.forEach(function (button) {
    const clipboard = new Clipboard(button, {
      text: function () {
        // Obtiene el valor del atributo 'data-clipboard-text' del botón
        return button.getAttribute("data-clipboard-text");
      },
    });
  });
});


document.addEventListener("turbo:load", () => {
  var toastElList = [].slice.call(document.querySelectorAll(".toast"));
  var toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl).show();
  });
});

["turbo:load", "turbo:frame-load"].forEach(function(evt) {
  document.addEventListener(evt, () => {
    document
      .querySelectorAll('[data-bs-toggle="lightbox"]')
      .forEach((el) => el.addEventListener("click", Lightbox.initialize));

    document.querySelectorAll(".clipboard-btn").forEach(function (button) {
      new Clipboard(button)
    });

    $(".js-range-slider").ionRangeSlider({
      skin: "square",
    });

    document.querySelectorAll("a[data-form]").forEach(function (link) {
      link.addEventListener("click", function (event) {
        event.preventDefault(); // Prevenir el comportamiento predeterminado del enlace
        const formId = this.getAttribute("data-form");
        const form = document.getElementById(formId);
        const actionValue = this.getAttribute("data-action");

        if (form) {
          // Crear o actualizar el campo oculto con el valor de data-action
          let hiddenField = form.querySelector('input[name="data_action"]');
          if (!hiddenField) {
            hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = "data_action";
            form.appendChild(hiddenField);
          }
          hiddenField.value = actionValue;

          // Enviar el formulario
          form.submit();
        }
      });
    });
  });
});

document.addEventListener("turbo:submit-start", function () {
  Turbo.navigator.delegate.adapter.showProgressBar();
});

$(document).on("turbo:before-cache", function () {
  $(".js-select2-form").each(function () {
    $(this).data("select2").destroy();
  });

  $(".js-range-slider").each(function () {
    $(this).data("ionRangeSlider").destroy();
  });

  var toastElList = [].slice.call(document.querySelectorAll(".toast"));
  var toastList = toastElList.map(function (toastEl) {
    new bootstrap.Toast(toastEl).dispose();
  });
});
